import { useState, useEffect } from "react";
import { EyeSlashIcon, EyeIcon } from "@heroicons/react/20/solid";

function FormInput({ label, type, inputMode, pattern, title, name, id, placeholder, disabled, value, setValue, required }) {

    const [showPassword, setShowPassword] = useState(true);
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        if (pattern && value) {
            const regex = new RegExp(pattern);
            setIsValid(regex.test(value));
        } else {
            setIsValid(true); // Reset validation if pattern or value is empty
        }
    }, [value, pattern]);

    const handleToggle = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
        let field = document.getElementById(id);
        field.type === "password" ? field.type = "text" : field.type = "password";
    }

    return (
        <div className={type === 'password' ? 'relative' : 'static'}>
            <label htmlFor={name} className='form-label'>
                {label}
            </label>
            <input 
                type={type}
                name={name}
                inputMode={inputMode}
                pattern={pattern}
                title={title}
                id={id}
                className="form-input disabled:opacity-75 disabled:bg-gray-200 dark:placeholder:text-gray-700 disabled:cursor-not-allowed disabled:text-gray-700"
                placeholder={placeholder}
                disabled={disabled}
                value={value}
                required={required}
                onChange={e => setValue(e.target.value)}
            />
            {!isValid && pattern && (
                <p className="text-red-500 text-sm mt-1">
                    {title || 'Invalid input format'}
                </p>
            )}
            {type === "password" && (
                showPassword ? (
                    <EyeSlashIcon className='absolute top-[78%] right-2 w-5 h-5 transform -translate-y-[78%] text-slate-300 hover:text-slate-500 cursor-pointer' onClick={handleToggle} />
                ) : (
                    <EyeIcon className='absolute top-[78%] right-2 w-5 h-5 transform -translate-y-[78%] text-slate-300 hover:text-slate-500 cursor-pointer' onClick={handleToggle} />
                )
            )}
        </div>
    )
}

export default FormInput