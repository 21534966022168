import { useLocation } from 'react-router-dom';
import { useValidatePhone } from 'hooks';
import { Button, FormInput } from 'components';
import { useState } from 'react';
import PhoneVerify from './PhoneVerify';

function VerifyAccount() {

    const location = useLocation();
    const { pathname } = location;
    const path = pathname.split("/");
    const phoneNumber = path[2];
    const [moving, setMoving] = useState(false);
    const [steps, setSteps] = useState(1);

    const { isLoading, client } = useValidatePhone({ phone : phoneNumber });

    const nextStep = () => {
        setMoving(true);
        setTimeout(() => {
            setMoving(false);
            setSteps(2);
        }, 1500);
    }

    return (
        <>
        {steps === 1 && (
        <main className="grid h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
                {isLoading ? 
                    <div className='flex justify-center items-center h-20'>
                        <div className='w-12 h-12 border-t-2 border-b-2 border-gold-700 dark:border-gold-500 rounded-full animate-spin' />
                    </div> 
                : 
                <>
                    <p className="text-base font-semibold text-gold-700">Welcome</p>
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                        Verify Your Account
                    </h1>
                    <p className="mt-6 text-base leading-7 text-gray-600">
                        We need to verify your account before you can proceed. Confirm your details below.
                    </p>
                    <div className="mt-4 max-w-4xl flex items-center justify-center gap-x-6">
                        <form className="mt-8 max-w-4xl sm:mt-8">
                            <div className="w-full grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-2">
                                <FormInput 
                                    type="text"
                                    name="otherNames"
                                    id="otherNames"
                                    value={client?.otherNames}
                                    disabled={true}
                                />
                                <FormInput 
                                    type="text"
                                    name="surname"
                                    id="surname"
                                    value={client?.surname}
                                    disabled={true}
                                />
                                <FormInput 
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    value={client.phone}
                                    disabled={true}
                                />
                            </div>
                            <div className="mt-5">
                                <Button 
                                    btnText={"Continue"}
                                    btnType={"button"}
                                    btnFunction={nextStep}
                                    btnClasses={"rounded-md bg-gold-500 px-3.5 py-2.5 text-sm font-semibold text-white w-full shadow-sm hover:bg-gold-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gold-500"}
                                    disabled={client?.isVerified || moving}
                                />
                            </div>
                            {client?.isVerified && (
                                <div className="mt-5">
                                    <p className='text-sm text-red-500'>
                                        Your account is already verified.
                                    </p>
                                </div>
                            )}
                        </form>
                    </div>
                    {/* <div className="mt-10 flex items-center justify-center gap-x-6">
                        <Link
                            to="/"
                            className="rounded-md bg-gold-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gold-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gold-500"
                        >
                            Go back home
                        </Link>
                        <Link to="/contact" className="text-sm font-semibold text-gray-900">
                            Contact support <span aria-hidden="true">&rarr;</span>
                        </Link>
                    </div> */}
                </>}
            </div>
        </main>
        )}

        {steps === 2 && (
            <main className="grid h-screen grid-cols-1 md:grid-cols-2 bg-white p-2">
                <div className='px-6 py-12 sm:py-12 lg:px-8 bg-white border rounded-lg'>
                    <PhoneVerify  
                        setSteps={setSteps}
                    />
                </div>

                <div className='hidden md:flex md:flex-col space-y-12 px-6 py-12 sm:py-12 lg:px-8'>
                    <div>
                        <h2 className='text-5xl font-semibold tracking-tight text-balance text-gray-900 sm:text-7xl'>
                            Let's verify your account
                        </h2>
                        <p className='mt-8 text-sm font-medium text-pretty text-gray-400 sm:text-xl/6'>
                            Please submit the following identification materials to verify your account.
                        </p>
                    </div>

                    <div>
                        <ul className='max-w-lg space-y-1 text-gray-500 list-inside divide-y divide-gray-100'>
                            <li className='flex items-center justify-between h-20 max-w-full'>
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 me-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" />
                                    </svg>
                                    <div className="flex items-start flex-col">
                                        <span className='font-semibold'>
                                            Take a picture of your valid GhanaCard ID (front view)
                                        </span>
                                        <span className='text-gray-400'>
                                            To confirm your personal details
                                        </span>
                                    </div>
                                </div>
                                <span>
                                    <svg className="w-3.5 h-3.5 me-2 text-green-500 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                    </svg>
                                </span>
                            </li>
                            <li className='flex items-center justify-between h-20 max-w-full'>
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 me-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" />
                                    </svg>
                                    <div className="flex items-start flex-col">
                                        <span className='font-semibold'>
                                            Take a picture of your valid GhanaCard ID (back view)
                                        </span>
                                        <span className='text-gray-400'>
                                            To confirm your personal details
                                        </span>
                                    </div>
                                </div>
                                <span>
                                    <svg className="w-3.5 h-3.5 me-2 text-green-500 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                    </svg>
                                </span>
                            </li>
                            <li className='flex items-center justify-between h-20 max-w-full'>
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 me-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                                    </svg>
                                    <div className="flex items-start flex-col">
                                        <span className='font-semibold'>
                                            Take a selfie of yourself
                                        </span>
                                        <span className='text-gray-400'>
                                            To match your face to your ID photo
                                        </span>
                                    </div>
                                </div>
                                <span>
                                    <svg className="w-3.5 h-3.5 me-2 text-green-500 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                    </svg>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </main>
        )}
        </>
    )
}

export default VerifyAccount