import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { show } from 'providers/toastSlice';
import { fetchRequests } from 'https/requests';

function useValidatePhone({ phone }) {

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [client, setClient] = useState({});

    useEffect(() => {

        const fetchClient = async(phone) => {
            setIsLoading(true);

            const url = `${process.env.REACT_APP_STAGING_AUTH}/verify-phone`;

            const headers = {
                "Content-Type" : "application/json"
            }

            const params = {
                phone : phone
            }

            try {

                let res = await fetchRequests(url, "get", headers, params, {});

                if(res?.err){
                    dispatch(show({
                        state : true,
                        message : `${res.err}`,
                        type : "error"
                    }))
                    return;
                }

                setClient(res)
                
            } catch (error) {
                dispatch(show({
                    state : true,
                    message : `${error}`,
                    type : "error"
                }))
            } finally {
                setIsLoading(false);
            }

        }

        fetchClient(phone);

    }, [dispatch, phone])

    return { isLoading, client }

}   

export default useValidatePhone;