import React, { useState } from 'react';
import { Photo } from 'components';
import { formatFileName } from 'components/misc/functions';

function PhoneVerify({ setSteps }) {

    const [currentStep, setCurrentStep] = useState(0);
    const [errors, setErrors] = useState({});
    const [fileError, setFileError] = useState('');
    const [filesData, setFilesData] = useState({
        frontPhoto: null,
        backPhoto: null,
        selfie: null
    });

    const handleFileChange = (event) => {
        const { name } = event;
        const selectedFile = event;
        setFilesData({ ...filesData, [formatFileName(name)]: selectedFile });
        setFileError(''); // Clear error on change
    };

    const validateStep = () => { 
        let newErrors = {};

        let file = currentStep === 0 ? filesData.frontPhoto : currentStep === 1 ? filesData.backPhoto : filesData.selfie;
        console.log(file);

        if (!file) {
            setFileError('Please upload a file');
            return false; // Prevent proceeding to the next step
        }
        // Add more file validation if needed (e.g., file type, size)
        if (file.size > 10 * 1024 * 1024) { // Example: 10MB limit
            setFileError('File size exceeds 10MB limit');
            return false;
        }
        if (!file.type.startsWith('image/')) { // Example: only allow image files
            setFileError('File is not an image');
            return false;
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const nextStep = () => {
        if (validateStep()) {
          setCurrentStep(currentStep + 1);
        }
    };
    
    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateStep()) {
            console.log('Form submitted:', filesData);
            // Perform actual submission (e.g., API call) here
        }
    };

    const renderStep = () => {
        switch (currentStep) {
            case 0:
                return (
                    <>
                    <Photo 
                        label={"Front photo of GhanaCard"}
                        name={"front-photo"}
                        value={filesData.frontPhoto}
                        setValue={handleFileChange}
                    />
                    <div className="grid grid-cols-2 gap-8 my-8">
                        <button type="button" onClick={prevStep} className='bg-gray-200 text-gray-700 px-4 py-2 rounded-lg'>
                            Previous
                        </button>
                        <button 
                            type="button" 
                            onClick={nextStep} 
                            className='rounded-md bg-gold-500 px-3.5 py-2.5 text-sm font-semibold text-white w-full shadow-sm hover:bg-gold-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gold-500'
                        >
                            Next
                        </button>
                    </div>
                    {fileError && <p className='text-red-500 text-sm'>{fileError}</p>}
                    </>
                )
            case 1:
                return (
                    <>
                    <Photo 
                        label={"Back photo of GhanaCard"}
                        name={"back-photo"}
                        value={filesData.backPhoto}
                        setValue={handleFileChange}
                    />
                    <div className="grid grid-cols-2 gap-8 my-8">
                        <button type="button" onClick={prevStep} className='bg-gray-200 text-gray-700 px-4 py-2 rounded-lg'>
                            Previous
                        </button>
                        <button 
                            type="button" 
                            onClick={nextStep} 
                            className='rounded-md bg-gold-500 px-3.5 py-2.5 text-sm font-semibold text-white w-full shadow-sm hover:bg-gold-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gold-500' 
                        >
                            Next
                        </button>
                    </div>
                    </>
                )
            case 2:
                return (
                    <>
                    <Photo 
                        label={"Selfie of yourself"}
                        name={"selfie"}
                        value={filesData.selfie}
                        setValue={handleFileChange}
                    />
                    <div className="grid grid-cols-2 gap-8 my-8">
                        <button type="button" onClick={prevStep} className='bg-gray-200 text-gray-700 px-4 py-2 rounded-lg'>
                            Previous
                        </button>
                        <button 
                            type="button" 
                            onClick={nextStep} 
                            className='rounded-md bg-gold-500 px-3.5 py-2.5 text-sm font-semibold text-white w-full shadow-sm hover:bg-gold-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gold-500' 
                        >
                            Next
                        </button>
                    </div>
                    </>
                )
            default:
                return (
                    <div>
                        <button type="button" onClick={nextStep}>Next</button>
                    </div>
                )
        }
    }
    
    return (
        <div>
            <div className='md:hidden'>
                {/* insert image here (if needed) */}
                <h2 className='text-5xl font-semibold tracking-tight text-balance text-gray-900 sm:text-7xl'>
                    Let's verify your account
                </h2>
                <p className='mt-8 text-sm font-medium text-pretty text-gray-400 sm:text-xl/6'>
                    Please submit the following identification materials to verify your account.
                </p>
            </div>

            <div className='grid grid-cols-4 gap-2 mt-8 place-items-center'>
                {Array.from({ length: 4 }).map((_, index) => (
                <div key={index}>
                    <div className={index <= currentStep ? 'bg-gold-500 text-white w-10 h-10 rounded-full flex items-center justify-center' : 'bg-gray-200 w-10 h-10 rounded-full flex items-center justify-center'}>
                        {index + 1}
                    </div>
                </div>
                ))}
            </div>

            <div className='my-8'>
                <form onSubmit={handleSubmit}>
                    {renderStep()}
                </form>
            </div>
        </div>
    )
}

export default PhoneVerify