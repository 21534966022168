import { useState } from 'react';
import { FormInput, FormSelect, Button } from 'components';
import { educationOptions, genderOptions, nationalityOptions, networkOptions, occupationOptions, employmentNatureOptions } from 'components/misc/constants';
import { extractDate } from 'components/misc/functions';
import { fetchRequests } from 'https/requests';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { show } from 'providers/toastSlice';
import { updateUserInfo } from 'providers/userSlice';
import { useUser } from 'hooks';
import { formatName } from 'components/misc/constants';

function Information({ profile }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { accessToken } = useUser();

    const [otherNames, setOtherNames] = useState(profile?.otherNames);
    const [surname, setSurname] = useState(profile?.surname);
    const [email, setEmail] = useState(profile?.email);
    const [pob, setPob] = useState(profile?.placeOfBirth);
    const [address, setAddress] = useState(profile?.address);
    const [nearestLandmark, setNearestLandmark] = useState(profile?.nearestLandmark ? profile?.nearestLandmark : "");
    const [digitalAddress, setDigitalAddress] = useState(profile?.digitalAddress);
    const [education, setEducation] = useState(profile?.education);
    const [gender, setGender] = useState(profile?.gender);
    const [phone, setPhone] = useState(profile?.phone);
    const [dob, setDob] = useState(extractDate(profile?.dob));
    const [network, setNetwork] = useState(profile?.network);
    const [nationality, setNationality] = useState(profile?.nationality);
    const [occupation, setOccupation] = useState(profile?.occupation ? profile?.occupation : "");
    const [employNature, setEmployNature] = useState(profile?.employNature ? profile?.employNature : "");
    const [nextOfKin, setNextOfKin] = useState(profile?.nextOfKin ? profile?.nextOfKin : "");
    const [nextOfKinPhone, setNextOfKinPhone] = useState(profile?.nextOfKinPhone ? profile?.nextOfKinPhone : "");
    const [nextOfKinAddress, setNextOfKinAddress] = useState(profile?.nextOfKinAddress ? profile?.nextOfKinAddress : "");
    const [nextOfKinRelationship, setNextOfKinRelationship] = useState(profile?.nextOfKinRelationship ? profile?.nextOfKinRelationship : "");
    const [processing, setProcessing] = useState(false);


    const handleSubmit = async(e) => {
        e.preventDefault();
        setProcessing(true);

        if(otherNames === "" || surname === "" || email === "" || phone === ""){
            dispatch(show({
                state : true,
                message : `Please fill in all required fields`,
                type : "error"
            }))
            setProcessing(false);
            return;
        }

        let data = { otherNames : formatName(otherNames), surname : formatName(surname), email, placeOfBirth : pob, address, nearestLandmark, digitalAddress, education, gender, phone, dob, network, nationality, occupation, employNature, nextOfKin, nextOfKinPhone, nextOfKinAddress, nextOfKinRelationship }

        let url = `${process.env.REACT_APP_STAGING_AUTH}/profile`;
        const headers = {
            "Authorization" : `${accessToken}`,
        }

        let res = await fetchRequests(url, "patch", headers, {}, data);
        setProcessing(false);

        if(res?.err){
            dispatch(show({
                state : true,
                message : `${res?.err}`,
                type : "error"
            }))
            return;
        }

        dispatch(show({
            state : true,
            message : `Update successful!`,
            type : "success"
        }))
        dispatch(updateUserInfo(res));

        setTimeout(() => {
            navigate("/profile", { replace : true })
        }, 1500);
    }

    return (
        <form className='grid grid-cols-2 gap-4 w-full space-y-4 md:space-y-0' onSubmit={handleSubmit}>
            <div className="col-span-full md:col-span-1">
                <FormInput 
                    label='First name'
                    type='text'
                    name='firstname'
                    id='firstname'
                    placeholder='Enter your first name'
                    value={otherNames}
                    setValue={setOtherNames}
                    disabled={profile?.isVerified}
                />
            </div>
            
            <div className="col-span-full md:col-span-1">
                <FormInput 
                    label='Surname'
                    type='text'
                    name='surname'
                    id='surname'
                    placeholder='Enter your surname'
                    value={surname}
                    setValue={setSurname}
                    disabled={profile?.isVerified}
                />
            </div>
            
            <div className="col-span-full md:col-span-1">
                <FormInput 
                    label='Email'
                    type='email'
                    name='email'
                    id='email'
                    placeholder='Enter your email address'
                    value={email}
                    setValue={setEmail}
                />
            </div>
            
            <div className="col-span-full md:col-span-1">
                <FormInput 
                    label='Phone Number'
                    inputMode='numeric'
                    pattern='[0-9]*'
                    name='phone'
                    id='phone'
                    placeholder='Enter your phone number'
                    value={phone}
                    setValue={setPhone}
                    disabled={profile?.isVerified}
                />
            </div>

            <div className="col-span-full md:col-span-1">
                <FormSelect 
                    label='Mobile Network'
                    name='network'
                    id='network'
                    content={networkOptions}
                    value={network}
                    setValue={setNetwork}
                />
            </div>
            
            <div className="col-span-full md:col-span-1">
                <FormSelect 
                    label='Education'
                    name='education'
                    id='education'
                    content={educationOptions}
                    value={education}
                    setValue={setEducation}
                />
            </div>
            
            <div className="col-span-full md:col-span-1">
                <FormSelect 
                    label='Gender'
                    name='gender'
                    id='gender'
                    content={genderOptions}
                    value={gender}
                    setValue={setGender}
                />
            </div>

            <div className="col-span-full md:col-span-1">
                <FormInput 
                    label='Date of birth'
                    type='date'
                    name='dob'
                    id='dob'
                    value={dob}
                    setValue={setDob}
                />
            </div>

            <div className="col-span-full md:col-span-1">
                <FormInput 
                    label='Place of birth'
                    type='text'
                    name='pob'
                    id='pob'
                    placeholder='Enter your place of birth'
                    value={pob}
                    setValue={setPob}
                />
            </div>

            <div className="col-span-full md:col-span-1">
                <FormSelect 
                    label='Nationality'
                    name='nationality'
                    id='nationality'
                    content={nationalityOptions}
                    value={nationality}
                    setValue={setNationality}
                />
            </div>

            <div className="col-span-full md:col-span-1">
                <FormInput 
                    label='Home Address'
                    type='text'
                    name='address'
                    id='address'
                    placeholder='Enter your home address'
                    value={address}
                    setValue={setAddress}
                />
            </div>

            <div className="col-span-full md:col-span-1">
                <FormInput 
                    label='Nearest Landmark'
                    type='text'
                    name='nearestLandmark'
                    id='nearestLandmark'
                    placeholder='Nearest landmark to your home address'
                    value={nearestLandmark}
                    setValue={setNearestLandmark}
                />
            </div>

            <div className="col-span-full md:col-span-1">
                <FormInput 
                    label='Digital Address'
                    type='text'
                    name='digitalAddress'
                    id='digitalAddress'
                    placeholder='Enter your digital address'
                    value={digitalAddress}
                    setValue={setDigitalAddress}
                    pattern="[A-Z]{2}-\d{3}-\d{4}"
                    title="Please enter the input in the format AA-XXX-XXXX"
                    disabled={profile?.isVerified}
                />
            </div>

            <div className="col-span-full md:col-span-1">
                <FormSelect 
                    label='Occupation'
                    name='occupation'
                    id='occupation'
                    content={occupationOptions}
                    value={occupation}
                    setValue={setOccupation}
                />
            </div>

            <div className="col-span-full md:col-span-1">
                <FormSelect 
                    label='Employment Nature'
                    name='employNature'
                    id='employNature'
                    content={employmentNatureOptions}
                    value={employNature}
                    setValue={setEmployNature}
                />
            </div>

            <div className="col-span-full md:col-span-1">
                <FormInput 
                    label="Next of Kin's Full Name"
                    type='text'
                    name='nextOfKin'
                    id='nextOfKin'
                    placeholder="Enter your next of kin's full name"
                    value={nextOfKin}
                    setValue={setNextOfKin}
                />
            </div>

            <div className="col-span-full md:col-span-1">
                <FormInput 
                    label="Next of Kin's Phone Number"
                    type='text'
                    name='nextOfKinPhone'
                    id='nextOfKinPhone'
                    placeholder="Enter your next of kin's phone number"
                    value={nextOfKinPhone}
                    setValue={setNextOfKinPhone}
                />
            </div>

            <div className="col-span-full md:col-span-1">
                <FormInput 
                    label="Next of Kin's Address"
                    type='text'
                    name='nextOfKinAddress'
                    id='nextOfKinAddress'
                    placeholder="Enter your next of kin's address"
                    value={nextOfKinAddress}
                    setValue={setNextOfKinAddress}
                />
            </div>

            <div className="col-span-full md:col-span-1">
                <FormInput 
                    label="Relationship to Next of Kin"
                    type='text'
                    name='nextOfKinRelationship'
                    id='nextOfKinRelationship'
                    placeholder="Enter your relationship to next of kin"
                    value={nextOfKinRelationship}
                    setValue={setNextOfKinRelationship}
                />
            </div>

            <div className='col-span-2 mt-6 inline-flex gap-6' style={{ marginTop : "30px"}}>
                <Button 
                    btnText={"Update"}
                    btnType={"submit"}
                    btnClasses={"form-button"}
                    processing={processing}
                />

                <Button 
                    btnText={"Cancel"}
                    btnType={"button"}
                    btnClasses={"w-full px-3 py-1.5 bg-slate-100 rounded-md"}
                    btnFunction={() => navigate("/profile", { replace : true })}
                />
            </div>
        </form>
    )
}

export default Information