import { roundNum } from "./constants";

const validateGhanaCard = (card) => {
    const ghanaCardRegex = /([A-Z]{3}(-[0-9]{9})+(-[0-9]{1}))/i;

    if (ghanaCardRegex.test(card)) {
        // console.log("Input value matches the pattern");
        return true;
    } else {
        // console.log("Input value does not match the pattern");
        return false;
    }
}

const validateLogin = (phone, password) => {
    const phoneRegex = /^[0-9]{10}$/;
    // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/; // check length of 8 and include alphabets, symbols, and numbers
    const phoneTest = phoneRegex.test(phone);
    const passwordTest = passwordRegex.test(password);
    const errors = {};

    if (!phone) {
        errors.phone = "Phone number is required";
    }
    if (!phoneTest) {
        errors.phone = "Wrong format, kindly submit it in this format: 0241234567";
    }
    if (!password) {
        errors.password = "Password is required";
    }
    if (!passwordTest) {
        errors.password = "Password length must be 8 characters";
    }

    return errors;
}

const validateRegister = (otherNames, surname, email, pob, address, nearestLandmark, postGPS, education, dob, nationality, password) => {
    
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const dobRegex = /^\d{4}-\d{2}-\d{2}$/;
    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/; 
    const passwordTest = passwordRegex.test(password);
    const errors = {};

    if (!otherNames) {
        errors.otherNames = "Other names is required";
    }

    if (!surname) {
        errors.surname = "Surname is required";
    }

    if (!email) {
        errors.email = "Email is required";
    }

    if (!emailRegex.test(email)) {
        errors.email = "Email is invalid";
    }

    if (!pob) {
        errors.pob = "Place of birth is required";
    }

    if (!address) {
        errors.address = "Address is required";
    }

    if (!nearestLandmark) {
        errors.nearestLandmark = "Nearest landmark is required";
    }

    if (!postGPS) {
        errors.postGPS = "Post GPS is required";
    }

    if (!education) {
        errors.education = "Education is required";
    }

    if (!dob) {
        errors.dob = "Date of birth is required";
    }

    if (!dobRegex.test(dob)) {
        errors.dob = "Date of birth is invalid";
    }

    if (!nationality){
        errors.nationality = "Nationality is required"
    }

    if (!passwordTest) {
        errors.password = "Password length must be 8 characters";
    }

    return errors;
}

function extractDate(input) {
    return input ? new Date(input).toISOString().split('T')[0] : undefined;
}

function getDateAfterTimeframe(timeframe) {
    timeframe = timeframe.toLowerCase();
    const currentDate = new Date();
    let dateAfterTimeframe;

    if(timeframe === 'daily'){
        dateAfterTimeframe = new Date(currentDate.getTime() + (1 * 24 * 60 * 60 * 1000));
    } else if (timeframe === 'weekly') {
        dateAfterTimeframe = new Date(currentDate.getTime() + (7 * 24 * 60 * 60 * 1000));
    } else if(timeframe === 'biweekly'){
        dateAfterTimeframe = new Date(currentDate.getTime() + (14 * 24 * 60 * 60 * 1000));
    } else if (timeframe === 'monthly') {
        dateAfterTimeframe = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
    } else if (timeframe === 'quarterly') {
        dateAfterTimeframe = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, currentDate.getDate());
    } else {
        throw new Error('Invalid timeframe');
    }

    return dateAfterTimeframe.toISOString().split('T')[0];
}

function getLastDayOfMonth(year, month) {
    // Create a new Date object for the first day of the next month
    const nextMonth = new Date(year, month, 1);
    
    // Subtract 1 day from the first day of the next month
    const lastDayOfMonth = new Date(nextMonth - 1);
    
    // Return the day component of the last day of the month
    return lastDayOfMonth.getDate();
}

const nextMonthStartDate = () => {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();

    let lastDay = getLastDayOfMonth(year, month);

    return new Date(year, month + 1, lastDay).toISOString().split("T")[0];
}

function nextPaymentDate(loan){

    // get the number of payments to be made to date.
    let ledgers = loan?.transactions;
    let repayments = ledgers.filter((led) => led.type === "repay").reduce((sum, led)=> sum + parseFloat(led.amount), 0);

    // option 2
    let indexOfScheduledDate = Math.round(repayments / loan?.accountInfo.premium);
    let totalToDate = loan?.accountInfo.premium * indexOfScheduledDate;

    // use standing order dates if any
    let day = repayments >= loan?.accountInfo.totalPayment ? (loan?.standingOrderDates?.length > 0 ? loan?.standingOrderDates[loan?.standingOrderDates.length - 1] : loan?.paymentDates[loan?.paymentDates.length - 1]) :
        loan?.standingOrderDates?.length > 0 ? 
        (repayments < totalToDate ? 
            loan.standingOrderDates[indexOfScheduledDate - 1] : loan?.standingOrderDates[indexOfScheduledDate]) : 
        (repayments < totalToDate ? loan?.paymentDates[indexOfScheduledDate - 1] : loan?.paymentDates[indexOfScheduledDate]);

    return day;
}

function base64ToFileConverter(image, filename) {
    const img = image.replace("data:image/jpeg;base64,", "");
    const imageContent = atob(img);

    const arrayBuffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(arrayBuffer);

    for (let i = 0; i < imageContent.length; i++) {
        view[i] = imageContent.charCodeAt(i);
    }
    const type = 'image/jpeg';
    const blob = new Blob([arrayBuffer], { type });
    return new File([blob], filename, { type });
}

function fileSize(size) {
    let sizeInMB = (size / (1024 * 1024)).toFixed(2);
    return sizeInMB + 'MB';
}

const maxFileSize = 1024 * 1024 * 1; // 1MB

const commissionCalc = (rate, duration, amount) => {
    let interest = roundNum(((rate / 100) / duration), 2);
    let perMonth = roundNum(((rate / 100) / duration) * amount, 2);
    let total = roundNum(perMonth * duration, 2);

    return { interest, perMonth, total }
}

const getFirstCharacter = (str) => {
    return str.charAt(0);
}

function formatFileName(name) {
    // 1. Trim whitespace and remove the file extension (if present)
    const baseName = name.trim().replace(/\.[^/.]+$/, "");

    // 2. Remove spaces and split into hyphen-separated parts
    const parts = baseName.replace(/ /g, '-').split('-');

    // 3. Convert to camelCase
    const camelCaseName = parts
    .map((part, index) => {
        if (index === 0) {
        return part.toLowerCase();
        }
        return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
    })
    .join("");

    return camelCaseName;
}

export {
    validateGhanaCard,
    validateLogin,
    validateRegister,
    extractDate,
    getDateAfterTimeframe,
    nextMonthStartDate,
    nextPaymentDate,
    base64ToFileConverter,
    fileSize,
    maxFileSize,
    commissionCalc,
    getFirstCharacter,
    formatFileName
};